import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';

@Component({
    selector: 'nw-menu-default',
    templateUrl: './menu-default.component.html',
    styleUrls: ['../styles/common.menu.scss'],
    standalone: true,
    imports: [CommonModule, RouterLinkWithHref]
})
export class MenuDefaultComponent implements OnInit {
    home: string;
    constructor() { }

    ngOnInit() {
        this.home = '/introducers';
    }
}
