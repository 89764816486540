import { Component, Input } from '@angular/core';

@Component({
  selector: 'nw-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.scss']
})
export class CovidComponent {
    @Input() cbils: boolean;
    @Input() broker: boolean;
    @Input() introducer: boolean;

  constructor() { }
}
