import { Component, Input } from '@angular/core';

@Component({
    selector: 'nw-contact-footer',
    templateUrl: './contact-footer.component.html',
    styleUrls: [
        './contact-footer.component.scss'
    ]
})
export class ContactFooterComponent {
    @Input() nf: boolean;

    constructor() {
    }
}
