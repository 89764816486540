import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthorizationState } from '@nw-auth/authorization.state';
import { AuthorizationStateModel } from '@nw-auth/authorization.interfaces';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MenuDefaultComponent } from '@nw-app/components/menu-default/menu-default.component';
import { MenuLoginComponent } from '@nw-app/components/menu-login/menu-login.component';
import { MenuLoggedInComponent } from '@nw-app/components/menu-logged-in/menu-logged-in.component';

@Component({
    selector: 'nw-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [CommonModule, NgOptimizedImage, MenuDefaultComponent, MenuLoginComponent, MenuLoggedInComponent]
})
export class HeaderComponent implements OnInit {
    @Input() empty: boolean = false;

    @Select(AuthorizationState.getUser) user$: Observable<AuthorizationStateModel>;

    @ViewChild('container') container;

    show = false;
    showLoggedInMenu = false;

    constructor(private store: Store, private router: Router) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (this.show) {
                    this.toggleCollapse();
                }
            }
        });
    }

    ngOnInit() {
        this.user$.subscribe(user => {
            this.showLoggedInMenu = user.isLoggedIn && !(
                user.userType === 'broker' && !user.broker_application_finished
            );
        });
    }

    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event']) // Added to make it work on iOS
    outClickHandler(event: Event): void {
        if (!this.container.nativeElement.contains(event.target)) {
            if (this.show) {
                this.toggleCollapse();
            }
        }
    }

    toggleCollapse() {
        this.show = !this.show;
    }
}
