<li class="nav-item level-top">
    <a class="nav-link no-border" [routerLink]="home">
        <div class="inner-link">Home</div>
    </a>
</li>
<li class="nav-item level-top dropdown">
    <a class="nav-link no-border" href="javascript:void(0)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="inner-link">About</div>
    </a>
    <div class="dropdown-menu">
        <a class="dropdown-item ml-lg-4" href="https://www.newable.co.uk/about-us/" rel=”noopener”>About us</a>
        <a class="dropdown-item ml-lg-4" routerLink="/about/faq">FAQ</a>
        <a class="dropdown-item ml-lg-4" href="https://8142945.fs1.hubspotusercontent-na1.net/hubfs/8142945/NBL%20-%20Product%20Guide.pdf">Product Guide</a>
        <a class="dropdown-item ml-lg-4" href="https://www.newable.co.uk/content-hub/" rel=”noopener”>News and Views</a>
        <a class="dropdown-item ml-lg-4" href="https://www.newable.co.uk/newable-business-loans-policies/" rel=”noopener”>Policies</a>
        <div class="dropdown-divider m-0 d-none d-lg-block"></div>
        <a class="dropdown-item" href="https://commerce.newable.co.uk/" rel=”noopener”>Visit Commerce.newable.co.uk</a>
    </div>
</li>
<li class="nav-item level-top">
    <a class="nav-link no-border" routerLink="/about/case-studies">
        <div class="inner-link">Case Studies</div>
    </a>
</li>
<li class="nav-item level-top">
    <a class="nav-link no-border" routerLink="/about/contact">
        <div class="inner-link">Contact</div>
    </a>
</li>
