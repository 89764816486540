import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

const PADDING = '000000';
declare const InstallTrigger: any;

export class CurrencyFormatter {
    readonly DECIMAL_SEPARATOR: string;
    readonly THOUSANDS_SEPARATOR: string;
    readonly CURRENCY_SIGN: string;

    constructor() {
        this.DECIMAL_SEPARATOR = '.';
        this.THOUSANDS_SEPARATOR = ',';
        this.CURRENCY_SIGN = '£';
    }

    transform(value: number | string, fractionSize: number = 2): string {
        let [integer, fraction = ''] = (value || '').toString()
            .split(this.DECIMAL_SEPARATOR);

        fraction = fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : '';

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        return this.CURRENCY_SIGN + (integer || 0) + fraction;
    }

    parse(value: string | number, fractionSize: number = 2): string {
        value = typeof value === 'number' ? String(value) : value;
        const _value = value.replace(this.CURRENCY_SIGN, '');
        let [integer, fraction = ''] = (_value || '').split(this.DECIMAL_SEPARATOR);

        integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

        fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : '';

        return integer + fraction;
    }
}

@Directive({
    selector: '[nwCurrencyInputDirective]'
})
export class CurrencyInputDirective implements OnInit {
    private el: HTMLInputElement;
    private formatter: CurrencyFormatter;

    readonly ALLOWED_CHARS: string[];
    private pressedControl: boolean;

    constructor(
        private elementRef: ElementRef,
    ) {
        this.el = this.elementRef.nativeElement;
        this.el.style.textAlign = 'right';
        this.el.type = 'text';

        this.formatter = new CurrencyFormatter();

        this.ALLOWED_CHARS = [
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
            this.formatter.DECIMAL_SEPARATOR, this.formatter.THOUSANDS_SEPARATOR
        ];

        this.pressedControl = false;
    }

    get isNotFirefox() {
        return !(typeof InstallTrigger === 'object');
    }

    ngOnInit() {
        this.el.value = this.formatter.transform(this.el.value);
    }

    @HostListener('focus', ['$event'])
    onFocus(e) {
        const value = e.target.value || '';
        this.el.value = this.formatter.parse(value);

        if (this.isNotFirefox) {
            this.el.type = 'number';
        }
    }

    @HostListener('blur', ['$event'])
    onBlur($event) {
        const value = $event.target.value;

        if (this.isNotFirefox) {
            this.el.type = 'text';
        }

        if (value !== '') {
            this.el.value = this.formatter.transform(value);
        } else {
            this.el.value = null;
        }
    }

    /**
     * Part of code for Firefox users
     */
    _protectDigits(e: KeyboardEvent) {
        if (this.isNotFirefox) {
            return;
        }

        if (
            (e.code.indexOf('Key') > -1 || e.code.indexOf('Digit') > -1)
            && this.ALLOWED_CHARS.indexOf(e.key) === -1
        ) {
            if (this.pressedControl) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
        } else {
            return e;
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyDownEvent(e: KeyboardEvent) {
        if (e.target !== this.el || this.isNotFirefox) {
            return;
        }
        if (e.key === 'Meta' || e.key === 'Control') {
            this.pressedControl = true;
        } else {
            this._protectDigits(e);
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyUpEvent(e: KeyboardEvent) {
        if (e.target !== this.el || this.isNotFirefox) {
            return;
        }
        if (e.key === 'Meta' || e.key === 'Control') {
            this.pressedControl = false;
        } else {
            this._protectDigits(e);
        }
    }
}
