import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[nwAmountInput]'
})
export class AmountInputDirective {
    private el: HTMLInputElement;
    @Input() max: number;
    @Input() min: number;
    @Output() ngModelChange = new EventEmitter();


    constructor(private elementRef: ElementRef, private control: NgControl) {
        this.el = this.elementRef.nativeElement;
    }

    private adjustInputValue(val) {
        if (val > this.max) {
            return this.max;
        }
        if (val < this.min) {
            return this.min;
        }
        if (val % 1000 !== 0) {
            return Math.trunc(val / 1000) * 1000;
        }

        return val;
    }

    @HostListener('blur', ['$event'])
    onBlur($event) {
        const value = $event.target.value;
        const newValue = this.adjustInputValue(value);
        this.el.value = newValue;
        this.control?.control?.setValue(newValue);
    }
}
