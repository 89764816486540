import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NwCommonModule } from '@nw-common/nw-common.module';
import { FooterComponent } from '@nw-app/components/footer/footer.component';
import { HeaderComponent } from '@nw-app/components/header/header.component';
import { LoginHeaderComponent } from '@nw-app/components/login-header/login-header.component';

@Component({
    selector: 'nw-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterOutlet, NwCommonModule, FooterComponent, HeaderComponent, LoginHeaderComponent]
})
export class AppComponent {
    constructor() {
    }
}
