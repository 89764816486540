<div class="nav-wrapper sticky-top">
    <nav #container class="navbar navbar-expand-lg navbar-light justify-content-between container">
        <div class="main-bar">
            <a class="navbar-brand">
                <img
                    ngSrc="assets/logo/newable-logo-new-base.png"
                    srcset="
                       assets/logo/newable-logo-new-base@2x.png 2x,
                        assets/logo/newable-logo-new-base@3x.png 3x"
                    alt="Newable Commerce logo"
                    class="navbar-logo"
                    height="44" width="159"
                priority>
            </a>

            <button type="button"
                    class="navbar-toggler"
                    [class.collapsed]="!show"
                    (click)="toggleCollapse()"
            >
                <div id="navbar-hamburger">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar top-bar"></span>
                    <span class="icon-bar middle-bar"></span>
                    <span class="icon-bar bottom-bar"></span>
                </div>
            </button>
        </div>

        <div class="collapse navbar-collapse" [class.show]="show">
            <ul class="navbar-nav ml-auto">
                <ng-container *ngIf="!empty">
                    <nw-menu-default [hidden]="showLoggedInMenu"></nw-menu-default>
                    <nw-menu-logged-in [hidden]="!showLoggedInMenu"></nw-menu-logged-in>
                    <nw-menu-login></nw-menu-login>
                </ng-container>
            </ul>
        </div>
    </nav>
</div>
<div class="nav-wrapper sticky-top nav-shadow"></div>
