import { Component } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserLogout } from '@nw-auth/authorization.actions';
import { AuthorizationState } from '@nw-auth/authorization.state';
import { AuthorizationStateModel } from '@nw-auth/authorization.interfaces';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';

@Component({
    selector: 'nw-menu-login',
    templateUrl: './menu-login.component.html',
    styleUrls: ['../styles/common.menu.scss'],
    standalone: true,
    imports: [CommonModule, RouterLinkWithHref]
})
export class MenuLoginComponent {
    @Select(AuthorizationState.getUser) user$: Observable<AuthorizationStateModel>;

    constructor(private store: Store) { }

    logout() {
        this.store.dispatch(new UserLogout());
    }
}
