import { LoanResponseEntity, LoanTypes, } from '@nw-loans/loans.interfaces';


export class GetApplications {
    static readonly type = 'GET_APPLICATIONS';

    constructor(public type: LoanTypes = "applications", public searchParam: string = null) {
    }
}

export class GetApplicationsSuccess {
    static readonly type = 'GET_APPLICATIONS_SUCCESS';

    constructor(public type: string, public payload: LoanResponseEntity) {
    }
}

export class GetApplicationsError {
    static readonly type = 'GET_APPLICATIONS_ERROR';

    constructor(public error: string, public type: string) {
    }
}

export class DeleteLoanApplication {
    static readonly type = 'DELETE_LOAN_APPLICATION';

    constructor(public id: number) {
    }
}

export class DeleteLoanSuccess {
    static readonly type = 'DELETE_LOAN_SUCCESS';

    constructor() {
    }
}

export class GetMambuData {
    static readonly type = 'GET_MAMBU_DATA';

    constructor(public payload: number[]) {
    }
}

export class GeneralActionError {
    static readonly type = 'GENERAL_ACTION_ERROR';

    constructor(public error: string = null) {
    }
}

export class GetPaymentSchedule {
    static readonly type = 'GET_PAYMENT_SCHEDULE';

    constructor(public loanId: number) {
    }
}

export class GetPaymentScheduleSuccess {
    static readonly type = 'GET_PAYMENT_SCHEDULE_SUCCESS';

    constructor() {
    }
}

export class SendAcceptanceEmail {
    static readonly type = 'SEND_ACCEPTANCE_EMAIL';

    constructor(public loanId: number) {
    }
}

export class SendAcceptanceEmailSuccess {
    static readonly type = 'SEND_ACCEPTANCE_EMAIL_SUCCESS';

    constructor(public emails: string[]) {
    }
}

export class ChangeApplicationsPage {
    static readonly type = 'CHANGE_APPLICATIONS_PAGE';

    constructor(public type: LoanTypes, public page: number) {
    }
}

export class UploadBankStatement {
    static readonly type = 'UPLOAD_BANK_STATEMENT';

    constructor(public file: FormData) {
    }
}

export class UploadBankStatementSuccess {
    static readonly type = 'UPLOAD_BANK_STATEMENT_SUCCESS';

    constructor() {
    }
}

export class GetMarketingPreference {
    static readonly type = 'GET_MARKETING_PREFERENCE';

    constructor() {
    }
}

export class GetMarketingPreferenceSuccess {
    static readonly type = 'GET_MARKETING_PREFERE_NCESUCCESS';

    constructor(public payload: any) {
    }
}

export class GetMarketingPreferenceError {
    static readonly type = 'GET_MARKETING_PREFERENCE_ERROR';

    constructor() {
    }
}

export class ChangeMarketingPreference {
    static readonly type = 'CHANGE_MARKETING_PREFERENCE';

    constructor(public payload: any) {
    }
}

export class ChangeMarketingPreferenceSuccess {
    static readonly type = 'CHANGE_MARKETING_PREFERENCE_SUCCESS';

    constructor() {
    }
}

export class ChangeMarketingPreferenceError {
    static readonly type = 'CHANGE_MARKETING_PREFERENCE_ERROR';

    constructor() {
    }
}
