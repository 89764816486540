import { Injectable } from '@angular/core';
import shared from '@nw-root/../shared.json';

const envs = {
    'Testing': ['test.newablelending.co.uk'],
    'Staging': ['dev.newablelending.co.uk'],
    'Production': ['newablelending.co.uk'],
};

@Injectable()
export class EnabledsService {

    private shared: any;
    private enableds: any;

    constructor() {
        this.shared = shared;
        this.enableds = this.getEnableds();
    }

    private getLocation() {
        return window.location.host;
    }

    private getEnvironment() {
        const host: string = this.getLocation();

        for (const env_name of Object.keys(envs)) {
            const env_list = envs[env_name];

            for (const env of env_list) {
                if (host.indexOf(env) !== -1) {
                    return env_name;
                }
            }
        }
        return 'Development';
    }

    private getEnableds() {
        return this.shared.enableds[this.getEnvironment()];
    }

    getEnabled(name: string) {
        return this.enableds[name];
    }
}
