import { Component } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'nw-menu-logged-in',
    templateUrl: './menu-logged-in.component.html',
    styleUrls: ['../styles/common.menu.scss'],
    standalone: true,
    imports: [CommonModule, RouterLinkWithHref]
})
export class MenuLoggedInComponent {

    constructor() {}
}
