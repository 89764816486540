import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonsState } from '@nw-common/commons.state';
import { Select } from '@ngxs/store';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'nw-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgOptimizedImage]
})
export class FooterComponent {
    @Select(CommonsState.cookieBannerVisible) cookiesBannerVisible: Observable<boolean>;

    constructor() {
    }
}
